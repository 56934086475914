import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Menu from "@components/Menu"
import Image from "../image"
import MissionStatement from "@components/MissionStatement"

const EventHeader = ({ imageSrc, halfHeight }) => {
  return (
    <>
      <Menu />
      <div class="relative overflow-hidden">
        <div style={{ height: "50vh" }} class="">
          <StaticImage
            className="h-full object-cover"
            height="50vh"
            src="../../images/3.jpg"
            placeholder="blurred"
            layout="fullWidth"
            alt="Page header image"
          />
        </div>
      </div>
    </>
  )
}

export default EventHeader
